body{
    padding: 0 !important;

    &.modal-open{
        padding-right: 17px !important;
    }
}
.pageHeader{
    padding: 14vh 0;
    background-position: center;
    background-size: cover;
    color: #fff;
}

.homeEdtech{
    background: #fff;
    padding: 15vh 0;
    text-align: left;

    h2{
        color: #f8ab24;
        font-weight: bold;
    }

    p{
        color: #fff;
        font-size: 1.1rem;
        font-weight: normal;
        padding: 1rem;
        background: rgba(0,0,0,0.6);
    }
}

.btn{
    &.BannerWithText_btn{
        background-color: #fff;
        border-radius: 0;
        border: 1px #000 solid;
        color: #000;
        margin-top: 25px;
        padding: 13px 25px;
        outline: none;
    
        &:hover,
        &:active,
        &:focus,
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active{
            background-color: #000;
            color: #fff;
            border: 1px #000 solid;
            outline: none;
        }
    
    }
}

.numberBox{
    text-align: center;
    border: 1px rgb(0, 30, 55) solid;
    padding: 10px;
    min-height: 120px;
    
    p{
        color: rgb(0, 30, 55);
    }
    span{
        display: block;
        font-size: 2rem;
        font-weight: bold;
    }

    &:hover{
        background: rgb(0, 30, 55);
        color: #fff;
    }
}

.BannerWithText_subheading{
    color: #fff;
    font-size: 2rem;
    font-weight: bold;
    text-shadow: 0px 0px 30px #333;
    margin: 20px 0;

    span{
        border-bottom: 3px solid #f8ab24;
        left: 8px;
        position: relative;
        padding-bottom: 5px;
    }
}

.engineContainer{
    background: #fff;
    padding: 12vh 0;

    h2{
        color: #000;
        font-weight: bold;
    }

    .col-md-8{
        padding-top: 15px;
        img{
            margin-right: 10px;
        }
    }
}

.imageBox{
    width: 80%;
    margin: auto;
    padding: 30px;
    background: #001e37;

    ul{
        list-style-type: none;
        padding: 0;
        text-align: left;
        margin: 0;

        li{
            color: #fff;
            padding: 0;
            font-size: 1.2rem;
            font-weight: bold;
            margin-bottom: 10px;

            span{
                display: inline-block;
                margin-right: 15px;
                width: 30px;
                height: 30px;
                line-height: 35px;
                text-align: center;
                font-size: 0.9rem;
                color: #001e37;
            }
        }
    }
}

.timingsDetails {
    margin-right: 17px;
    margin-left: 2px;
    color: #ff0863;

    button {
        // float: right;
        position: relative;
        // top: 0px;
        left: -7px;
        // padding: 2px 8px;
        font-size: 1rem;
        border: none;
        background: transparent;
        color: #000;
        
        &:hover {
            span {
                color: #fff !important;
                border-color: #fff !important;
            }
        }

        svg {
            margin-right: 20px;
        }
    }
}

.popover-header {
    background-color: #ffe754 !important;
}

.teacherDetails {
    // font-family: "Caveat",cursive;
    // font-size: 14px;
    position: relative;
    top: 5px;
    display: inline;
    margin-right: 24px;

    .tutorThumb {
        width: 40px;
        height: 40px;
        border: 1px #9fbdde solid;
        padding: 2px;
        // margin-left: 12px;
        display: inline-block;
        position: relative;
        border-radius: 50%;
    }
}

.classTimingsCourse {
    border: 1px #ccc solid;
    overflow: hidden;
    border-radius: 10px;
    margin: 30px 0;
    box-shadow: 0px 5px 30px -17px #333;
}

.classTeachersCourse {
    padding: 15px;
    border-radius: 10px;
    margin: 30px 0;
    box-shadow: 0px 5px 30px -17px #333;
    list-style-type: none;
    
    li {
       &:first-child {
            border-bottom: 1px #ccc solid;
            margin-bottom: 12px;
       } 
       &:not(:first-child) {
            font-family: "Caveat",cursive;
            font-size: 14px;
            position: relative;
            padding: 5px 0;

            .tutorThumb {
                width: 34px;
                height: 34px;
                border: 1px #ccc solid;
                // margin-left: 12px;
                display: inline-block;
                position: relative;
                border-radius: 50%;
            }
        }
    }

}

.courseCategoryHeading {
    text-align: left;
    padding: 7px;
    border-bottom: 1px #000 solid;
    margin-bottom: 30px;
}

.modal-backdrop {
    &.show{
        opacity: 0.7;
    }
}

.closeModalLogin{
    position: absolute;
    top: 12px;
    right: 12px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    text-align: center;
    background: #0a152b;
    border-radius: 50%;
    cursor: pointer;
    z-index: 999;
    transition: all 0.15s ease-in-out;

    &:hover{
        color: #0a152b;
        background: #fff;
    }
}

.onlyMobile{
    display: none;
}

// .couponBoxDesign{
//     .col-md-9 {
//         padding-right: 0;
//     }
//     .col-md-3 {
//         padding-left: 0;
//     }
//     button,
//     input {
//         border-radius: 0;
//     }
// }



// Css for select options field

/**
 * Main wrapper
 */
 .select-search {
    width: 90%;
    position: relative;
    // font-family: 'Nunito Sans', sans-serif;
    font-family: 'Open Sans', sans-serif;
    box-sizing: border-box;
    margin: auto;
}

.select-search *,
.select-search *::after,
.select-search *::before {
    box-sizing: inherit;
}

/**
 * Value wrapper
 */
.select-search__value {
    position: relative;
    z-index: 1;
}

.select-search__value::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
}

/**
 * Input
 */
.select-search__input {
    display: block;
    height: auto;
    width: 100%;
    padding: 5px 30px;
    background: #fff;
    border: 1px solid transparent;
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    outline: none;
    color: #fec63d;
    // font-family: 'Noto Sans', sans-serif;
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    text-overflow: ellipsis;
    line-height: 36px;
    -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
    -webkit-appearance:none;
}

.select-search__input:not([readonly]):focus {
    cursor: initial;
}

/**
 * Options wrapper
 */
.select-search__select {
    background: #fff;
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
    top: calc(100% + 2px) !important;
}

/**
 * Options
 */
.select-search__options {
    list-style-type: none;
    padding: 0;
}

/**
 * Option row
 */
.select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
}

/**
 * Option
 */
.select-search__option {
    display: block;
    height: auto;
    width: 100%;
    padding: 10px 16px;
    background: #fff;
    border: none;
    outline: none;
    // font-family: 'Noto Sans', sans-serif;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    color: #fec63d;
    cursor: pointer;
}

.select-search--multiple .select-search__option {
    height: 48px;
}

.select-search__option.is-selected {
    background: #fec63d47;
    color: #ff8d00;
    font-weight: bold;
    // font-size: 20px;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
    background: #fec63d47;
    color: #ff8d00;
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
    background: #fec63d;
    color: #fff;
}

/**
 * Group
 */
.select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
}

/**
 * States
 */
.select-search.is-disabled {
    opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
}

/**
 * Modifiers
 */
.select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
    transform: rotate(45deg);
    border-right: 1px solid #fec63d;
    border-bottom: 1px solid #fec63d;
    pointer-events: none;
}

.select-search--multiple .select-search__input {
    cursor: initial;
}

.select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
    border-color: #fec63d;
}


.select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 37px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 250px;
}

.select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
}







.glow-on-hover {
    max-width: 500px;
    // height: 100px;
    border: none;
    outline: none;
    color: rgb(0, 123, 255) !important;
    background: #fff !important;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    padding: 20px 50px !important;
    font-size: 26px !important;
    font-weight: bold !important;


    @media screen and (max-width: "700px"){
        font-size: 16px !important;
        width: 70vw;
        padding: 10px !important;
    }
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -15px;
    left:-15px;
    background-size: 400%;
    z-index: -1;
    filter: blur(15px);
    width: calc(100% + 30px);
    height: calc(100% + 30px);
    animation: glowing 20s linear infinite;
    opacity: 1;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.glow-on-hover:active {
    color: #000
}

.glow-on-hover:active:after {
    background: transparent;
}

.glow-on-hover:hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

.react-confirm-alert-overlay {
    z-index: 9999;
}


.loginPopupMain {
    .LoginForm {
        .form-group {
            input[type=email]{
                border: 1px #ccc solid;
            }
        }
    }
}

.checkoutpayment {
    
    .nav-pills {
        .nav-link {
            width: 70%;
            margin: auto;
        }
    }
}