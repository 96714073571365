.Footer{
    background: #000;
    color: #fff;

    h5{
        font-size: 16px;
        padding-bottom: 15px;
        margin-bottom: 20px;
        border-bottom: 1px #333 solid;
    }

    ul{
        list-style-type: none;
        padding: 0;

        li{
            // padding-left: 10px;
            a{
                color: #999;
                text-decoration: none;

                &:hover{
                    color: #fff;
                }
            }
        }
    }
    .footerSocial{
        img{
            margin-right: 15px;
        }
    }
}

@import './generic.scss'; // importing the generic css for whole project

@media screen and (max-width: '767px'){ //mobile fixes
    @import './responsive.scss';
}