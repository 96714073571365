.loginPopupMain{
    // color: #fff;

    &.signup{
        &.modal-dialog{
            max-width: 50vw;
        }
    }
    
    .modal-content{
        // background-color: #1b2843;
        // background-color: #fff;
    }
    &.modal-dialog{
        // max-width: 80vw;
    }

    .LoginForm,
    .SignUpForm{
        .form-group{
            // margin-bottom: 0.5rem;
            input[type=text],
            input[type=email],
            input[type=password],
            input[type=date],
            select{

                // background: #293e69;
                // padding: 14px;
                // height: 48px;
                // color: #fff;
                // outline: none;
                // border: none;

                background: transparent;
                padding: 14px;
                height: 48px;
                // color: #fff;
                color: #000;
                outline: none;
                border: none;
                border-bottom: 1px #666 solid;
                border-radius: 0;

                option{
                    color: #000;
                }

                &::placeholder{
                    color: #333;
                }
            }

            p{
                &.error{
                    font-size: 12px;
                    line-height: 17px;
                    color: red;
                    position: absolute;
                    right: 20px;
                    top: 0px;
                    padding: 0 8px;
                    background: #f3f3f3;
                    border-radius: 10px;
                }
            }
        }

        .genderBox,
        .termsBox{
            label {
                // padding-left: 20px;
                // margin-right: 20px;

                input{
                    // width: 16px;
                    // height: 16px;
                }
            }
            .custom-control{
                display: inline-block;
                margin-left: 15px;

                .custom-control-label{
                    &::before{
                        background-color: transparent;
                        border: #dea700 solid 1px;
                    }
                }
                .custom-control-input:checked~.custom-control-label::before{
                    border-color: #dea700;
                    background-color: #dea700;
                }
            }
        }
    }

    .anchorLink {
        display: inline-block;
        color: #004eff;
        padding: 1px 5px;
        border-bottom: 1px #2567fd solid;
        cursor: pointer;
    }

    .closeModalLogin{
        background: #eaeaea;
    }

    .errorMessage{
        color: red;
    }

    .imageSignup{
        position: absolute;
        bottom: -1px;
    }


    &.enrollmentModal {
        .closeModalLogin {
            background: red;
            border: 3px #fff solid;
            color: #fff;
            font-size: 16px;
            line-height: 24px;
            font-weight: bold;
        }
        .backButtonModal {
            position: absolute;
            top: 12px;
            left: 12px;
            width: 30px;
            height: 30px;
            line-height: 30px;
            font-size: 12px;
            text-align: center;
            background: yellow;
            border: 1px #000 solid;
            border-radius: 50%;
            cursor: pointer;
            z-index: 999;
            transition: all 0.15s ease-in-out;

            svg {
                color: #000;
                font-size: 12px;
            }
        }

        .UsernamesListings {
            background: #24c4ff;
            color: #fff;
        }

    }

}


.PhoneNumber{
    position: relative;
    overflow: hidden;
    background: #fec63d;
    color: #fec63d;
    min-height: 620px;

    @media screen and (max-width: '767px'){
        min-height: calc(100vh);
    }

    // .wrapper {
    //     position: absolute;
    //     width: 200%;
    //     height: 600px;
    //     left: -50%;
    //     top: 30vh;
    //     background-color: #fec63d;
    //     border-radius: 50%;
    // }

    h3 {
        color: inherit;
        text-align: center;
        margin: 12vh 0;
        padding: 0 35px;
        color: #fff !important;
    }
    
    .phoneStyle {
        width: 90%;
        margin: 15px auto;
        font-family: 'Open Sans', sans-serif;

        .col-4 {
            padding: 0;
            input {
                text-align: right;
                background: #fff;
                border: none;
                border-radius: 0;
                height: 47px;
                color: #fec63d;
                font-size: 18px;
                padding-right: 0;
                font-weight: bold;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }

            .flagImg {
                position: absolute;
                left: 7px;
                top: 10px;
                width: 24px;
                // height: 24px;
                // border-radius: 50%;
                // padding: 6px 0;
                margin: 5px 0;
                // background: #ccc;
                border: 1px #eaeaea solid;
            }

        }
        .col-8 {
            padding: 0;
            position: relative;
            .form-group{
                margin: 0;
                // background: #fff;
            }
            input{
                border-radius: 0;
                border: none !important;
                box-shadow: none;
                height: 47px;
                color: #fec63d;
                font-size: 18px;
                padding-left: 5px;
                font-weight: bold;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
                
            }
            hr{
                margin: 0;
                width: 90%;
                position: relative;
                top: -10px;
                left: 3px;
                height: 1px;
                background: #fec63d;
            }
        }
    }

}

.PhoneNumberVerificationCode {
    position: relative;
    overflow: hidden;
    background: #fec63d;
    color: #fff;
    min-height: 570px;

    @media screen and (max-width: '767px'){
        min-height: calc(100vh);
    }

    h3 {
        color: #fff;
        font-size: 1.2rem;
        padding: 10vh 0 0 0;
    }

    // .wrapper {
    //     position: absolute;
    //     width: 200%;
    //     height: 600px;
    //     left: -50%;
    //     top: 32vh;
    //     background-color: #fec63d;
    //     border-radius: 50%;
    // }

    
    input{
        
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        
    }

    .countDownBlockVerify {
        display: inline-block;
        width: 100%;
        // height: 50px;
        margin: 25px 0px 25px 0;
        border-radius: 50%;
        // background: #fff;
        font-family: inherit;
        font-size: 1.4rem;
        font-weight: 500;
        text-align: center;
        line-height: 30px;
    }
    

}

.UsernamesListings{
    position: relative;
    overflow: hidden;
    background: #fff;
    color: #24c4ff;
    min-height: 570px;

    // .wrapper {        
    //     position: absolute;
    //     width: 200%;
    //     height: 600px;
    //     left: -50%;
    //     top: 25vh;
    //     @media screen and (max-width: '767px'){
    //         top: 32vh;
    //     }
    //     background-color: #24c4ff;
    //     border-radius: 50%;
    // }

    h3 {
        color: inherit;
        text-align: center;
        margin: 10vh 0 0 0;
        padding: 0 35px;
        font-size: 2.5rem;
    }

    > .container {
        padding: 0;
    }

    .phoneStyle {
        margin: 0 !important;

        > .col-12 {
            padding: 0;
        }

        .registeredUsersList {
            position: relative;
            background-color: #24c4ff;
            margin: 0;
            padding: 30px;

            // &::before {
            //     content: '';
            //     width: 200%;
            //     height: 600px;
            //     position: absolute;
            //     left: 0;
            //     top: -20px;
            //     background-color: #24c4ff;
            //     z-index: 0;
            // }
        }
    }

    .registeredUsersList {
        list-style-type: none;
        padding: 0 10px;

        li {
            padding: 8px 0;
            cursor: pointer;

            &:hover {
                button {
                    border-color: #2a6a82;
                }
            }

            button {
                display: inline-block;
            }
            p {
                padding: 10px 0;
                padding-left: 15px;
                display: inline-block;
                color: #fff;
                font-size: 18px;
                font-weight: 600;
                margin: 0;
                line-height: 1.3rem;

                span {
                    font-size: 14px;
                    font-weight: 400;
                    color: #2a6a82;
                }
            }
        }

        button {
            background: #fff;
            color: #24c4ff;
            position: relative;
            font-weight: 600;

            &:not(.btn-block) {
                border-color: #fff;
                line-height: 22px;
                float: left;
            }

            &.btn-block {
                padding: 15px 0;
                padding-left: 40px;
            }

            svg {
                margin: 0;
                background: #24c4ff;
                width: 35px;
                height: 35px;
                position: absolute;
                top: 12px;
                left: 12px;
                padding: 10px;
                border-radius: 50%;
            }

        }
    }

    button {
        &.btn-lg {
            &.btn-primary {
                background: #fff;
                color: #24c4ff;
                // font-size: 1.5rem;
                // font-weight: 500;
            }
        }
    }

}

.UsernameField {
    position: relative;
    overflow: hidden;
    background: #24c4ff;
    color: #fff;
    min-height: 570px;

    @media screen and (max-width: '767px'){
        min-height: calc(100vh);
    }
    
    // .wrapper {
    //     position: absolute;
    //     width: 200%;
    //     height: 600px;
    //     left: -50%;
    //     top: 25vh;
    //     @media screen and (max-width: '767px'){
    //         top: 30vh;
    //     }
    //     background-color: #24c4ff;
    //     border-radius: 50%;
    // }


    input {
        border: none;
        padding: 28px 0;
        width: 90%;
        margin: auto;
        
         &::-webkit-input-placeholder { /* Edge */
            color: #ccc;
            font-size: 1rem;
          }
          
          &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #ccc;
          }
          
          &::placeholder {
            color: #ccc;
          }
    }

    .nextButtonNew {
        svg {
            color: #24c4ff !important;
        }
    }

}

.UserPassword {
    position: relative;
    overflow: hidden;
    background: #24c4ff;
    color: #fff;
    min-height: 570px;
    
    @media screen and (max-width: '767px'){
        min-height: calc(100vh);
    }

    label {
        color: #fff;
        font-size: 12px;
        margin: 0;
        padding-left: 20px;
    }

    input {
        margin-top: 0 !important;
        border: none;
        padding: 28px 0;
        width: 90%;
        margin: auto;
        
        &::-webkit-input-placeholder { /* Edge */
            color: #ccc;
            font-size: 1rem;
        }
        
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #ccc;
        }
        
        &::placeholder {
            color: #ccc;
        }
    }

    // .wrapper {
    //     position: absolute;
    //     width: 200%;
    //     height: 600px;
    //     left: -50%;
    //     top: 22vh;
    //     @media screen and (max-width: '767px'){
    //         top: 30vh;
    //     }
    //     background-color: #24c4ff;
    //     border-radius: 50%;
    // }

    
    @media screen and (max-width: '767px'){
        h3 {
            margin: 15vh 0 !important;
        }
    }

    .nextButtonNew {
        svg {
            color: #24c4ff !important;
        }
    }


}

.UserDetails {
    position: relative;
    overflow: hidden;
    background: #ff00a2;
    color: #fff;
    min-height: 570px;

    @media screen and (max-width: '767px'){
        min-height: calc(100vh);
    }
    
    label {
        color: #fff;
        font-size: 12px;
        margin: 0;
        padding-left: 20px;
        text-align: left;
        width: 100%;
    }

    input {
        text-align: left;
        margin-top: 0 !important;
        border: none;
        padding: 28px 15px;
        width: 90%;
        margin: auto;
        
        &::-webkit-input-placeholder { /* Edge */
            color: #ccc;
            font-size: 1rem;
        }
        
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #ccc;
        }
        
        &::placeholder {
            color: #ccc;
        }
    }

    // .wrapper {
    //     position: absolute;
    //     width: 300%;
    //     height: 800px;
    //     left: -100%;
    //     top: 18vh;
    //     @media screen and (max-width: '767px'){
    //         top: 22vh;
    //     }
    //     background-color: #ff00a2;
    //     border-radius: 50%;
    // }

    .btn.btn-secondary.btn-md {
        padding: 7px 7px;
        @media screen and (max-width: '767px'){
            padding: 10px 16px;
        }
    }

    .nextButtonNew {
        svg {
            color: #ff00a2 !important;
        }
    }

    // h3 {
    //     margin: 10vh 0 !important;
    // }

}

.UserEmailAddress {
    position: relative;
    overflow: hidden;
    background: #fff;
    color: #ff00a2;
    min-height: 570px;

    label {
        color: #fff;
        font-size: 12px;
        margin: 0;
        padding-left: 20px;
    }

    input {
        text-align: left;
        margin-top: 0 !important;
        border: none;
        padding: 28px 15px;
        width: 90%;
        margin: auto;
    }

    .wrapper {
        position: absolute;
        width: 300%;
        height: 800px;
        left: -100%;
        top: 28vh;
        @media screen and (max-width: '767px'){
            top: 29vh;
        }
        background-color: #ff00a2;
        border-radius: 50%;
    }    


    .nextButtonNew {
        svg {
            color: #ff00a2 !important;
        }
    }
}

.CheckoutPromoCode {
    position: relative;
    overflow: hidden;
    background: #fff;
    color: #ff00a2;
    min-height: 570px;

    label {
        color: #fff;
        font-size: 12px;
        margin: 0;
        padding-left: 10px;
    }

    input {
        margin-top: 0 !important;
        border: none;
        text-align: left;
    }

    .wrapper {
        position: absolute;
        width: 700px;
        height: 600px;
        left: -200px;
        top: 160px;
        background-color: #ff00a2;
        border-radius: 50%;
    }
    
}

.nextButtonNew {

    &.btn {
        width: 90%;
        margin: 0 auto 30px auto;
        background: transparent !important;
        border: none;
        box-shadow: none !important;
        text-align: center;
        font-size: 2rem;
        font-family: 'Open Sans', sans-serif;

        svg {
            padding: 13px;
            width: 46px;
            height: 46px;
            background: #fff;
            margin-left: 15px;
            position: relative;
            top: 5px;
            border-radius: 50%;
            color: #fec63d;
        }
    }
    
}



// .modal-backdrop{
//     background-color: #fff !important;
//     &.show{
//         opacity: 0.8 !important;
//     }
// }