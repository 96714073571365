.CareerPageSnippetComponent{
    padding: 20vh 0 15vh 0;
    position: relative;
    overflow: hidden;

    .BannerWithText_heading{
        color: #fff;
        font-size: 3.3rem;
        font-weight: bold;
        text-shadow: 2px 3px 15px rgba(0,0,0,0.7);
    }
    .BannerWithText_paragraph{
        margin-top: 20px;
        color: #fff;
        font-size: 1rem;
    }

    .BannerWithText_btn{
        background-color: #f4d506;
        border-radius: 0;
        border: 5px #fff solid;
        color: #000;
        margin-top: 25px;
        padding: 15px 30px;
        outline: none;
        font-size: 1.5rem;

        &:hover,
        &:active,
        &:focus,
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active{
            background-color: #000;
            border: 5px #fff solid;
            outline: none;
        }

    }

    img{
        max-width: 85%;
    }

    &.gray{
        background: #ccc;

        * {
            color: #fff !important;
        }
    }

    &.yellow{
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: cover !important;
        background-attachment: inherit !important;
    }

    &.blue{
        .BannerWithText_btn{
            background-color: #0070c0;
            color: #fff;
    
            &:hover,
            &:active,
            &:focus,
            &:not(:disabled):not(.disabled).active,
            &:not(:disabled):not(.disabled):active{
                background-color: #000;
                border: 5px #fff solid;
                outline: none;
            }
    
        }
    }

    &.white{
        .BannerWithText_heading{
            color: #0070c0;
            font-size: 2rem;
            font-weight: bold;
            text-align: left !important;
            text-shadow: none;
            position: relative;

            &::after{
                content: '';
                width: 34%;
                height: 7px;
                background: #ff7a92;
                position: absolute;
                bottom: -17px;
                left: 0;
                border-radius: 15px;
            }
        }
        .BannerWithText_paragraph{
            margin-top: 60px !important;
            text-align: left;
            margin-top: 20px;
            color: #000;
            font-size: 1.4rem;
        }    
    }

}