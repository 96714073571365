.SocialLoginComponent{
    .facebookLoginButton{
        &.metro{
            width: 48%;
            background: url('./../../images/login/loginFacebook.png') !important;
            background-color: #3c66c4 !important;
            background-position: center !important;
            background-repeat: no-repeat !important;
            background-size: contain !important;
            height: 45px;
            border: none;

            &:hover{
                opacity: 0.9;
            }
        }
    }
    button{
        margin-top: 5px;
        margin-bottom: 6px;
        margin-right: 2%;
        padding: 17.5px;
        outline: none !important;
    }
    .googleLoginButton {
        width: 48%;
        background-image: url('./../../images/login/loginGoogle.png') !important;
        background-color: #cf4332 !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: contain !important;
        height: 45px;
        border: none !important;
        border-radius: 0 !important;
        
        > div {
            opacity: 0;
        }
    }
}