.TextWithImageMethod{
    padding: 10vh 0;
    position: relative;
    overflow: hidden;

    .BannerWithText_heading{
        color: #000;
        font-size: 2.4rem;
    }
    .BannerWithText_paragraph{
        margin-top: 20px;
        color: #000;
        font-size: 1rem;
    }

    .BannerWithText_btn{
        background-color: #f4d506;
        border-radius: 0;
        border: none;
        color: #000;
        margin-top: 25px;
        padding: 15px 30px;
        outline: none;

        &:hover,
        &:active,
        &:focus,
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active{
            background-color: #000;
            border: none;
            outline: none;
        }

    }

    img{
        max-width: 85%;
    }

    &.gray{
        background: #ccc;

        * {
            color: #fff !important;
        }
    }

    &.yellow{
        background-image: url('./../../images/store-bg-1.png') !important;
        background-repeat: no-repeat !important;
        background-position: bottom left !important;
        background-size: contain !important;
        background-attachment: inherit !important;
    }

    .circle{
        width: 320px;
        height: 320px;
        border-radius: 50%;
        background-color: #ffc62c;
        padding-top: 0px;
        position: relative;
        margin: auto;
        // margin-top: 70px;

        img{
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 310px;
            left: 0;
            right: 0;
        }
    }
}