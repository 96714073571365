.HomeComponent{
    .engineBox{
        color: #000;
        font-size: 2rem;
        line-height: 2.2rem;
        width: 180px;
        height: 180px;
        text-align: center;
        padding: 2rem 0;
        background: #f8ab24;
        border-radius: 50%;
        border: 2px #fff solid;
        margin: auto;
    }
}