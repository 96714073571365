.CareersThanksForApplyingComponent {
    
    text-align: center;

    > img {
        display: block;
        margin: -1px auto;
        max-width: 100%;
    }

    .countDownBlock {
        h2{
            color: #fff;
            font-weight: 400;
            font-family: inherit;
            font-size: 2.4rem !important;
            margin: 0;
        }
        span {
            color: #fff;
            font-size: 16px;
        }
    }
    
}