.Partnerships{

}

#react-youtube-channel-container{
    // .iframe-container,
    // #outer-video-list-container
    // {
    //     width: 100% !important;
    //     background-color: transparent !important;
    // }

    // .video-container{
    //     max-width: 30%;
    //     display: inline-block;
    //     float: left;
    // }
    #outer-video-list-container{
        overflow-y: auto !important;
        height: 500px !important;
    }
}

.partnershipContent{
    &.overlay{
        position: relative;
        &:before{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 0;
            background: rgba(1, 4, 23, 0.8);
        }
    }
}