.App{
  font-family: 'Josefin Sans', sans-serif;
}

.MainHeader{
    .navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover{
        color: #000;
    }
    .bg-dark{
        background: #fafafa !important;
        text-transform: uppercase;
        img{
            height: 60px;
            width: auto;
        }
        .nav-item{
            padding: 0 6px;
            
            a{
                color: #000;
                transition: all 0.2s ease-in-out;

                &:hover{
                    color: #f4d506;
                }
                &:active{
                    color: #000 !important;
                    border: none;
                }
            }
        }
    }
    .dropdown-menu{
        left: 0;
        right: auto;
        background: white;
        border-radius: 0;
        top: calc(100% + 22px);
        margin: 0;
        color: #000;
        padding: 0;
        min-width: 230px;

        .dropdown-item{
            color: #000;
            outline: none;
            font-size: 14px;
            padding: 0;
            transition: 0.2s all ease-in-out;

            a{
                padding-left: 20px;
            }

            &:hover{
                background: transparent;
                color: #f4d506;
            }
        }

        .dropdown-divider{
            margin: 0;
            border-color: #333;
        }

        &.userDropDown{
            top: calc(100% + 20px);
            min-width: 100px;
        }
    }
}

.CongratulationsComponent{
    .MainHeader{
        .navbar{
            display: none;
        }
    }
}

.onlineSchoolBtn {
    background: #ffb400;
    color: #000 !important;
    border-radius: 30px;
    padding: 8px 25px !important;
    font-size: 14px;

    &:hover{
        background: #fff;
        // color: #ffb400 !important;
    }
}

.userDropDownMain {
    > a {
        background: #ffb400;
        color: #000 !important;
        border-radius: 30px;
        padding: 8px 25px !important;
        font-size: 14px;
    
    }
}

.navbar-dark {
    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGhlaWdodD0iMzJweCIgaWQ9IkxheWVyXzEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDMyIDMyOyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgMzIgMzIiIHdpZHRoPSIzMnB4IiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48cGF0aCBkPSJNNCwxMGgyNGMxLjEwNCwwLDItMC44OTYsMi0ycy0wLjg5Ni0yLTItMkg0QzIuODk2LDYsMiw2Ljg5NiwyLDhTMi44OTYsMTAsNCwxMHogTTI4LDE0SDRjLTEuMTA0LDAtMiwwLjg5Ni0yLDIgIHMwLjg5NiwyLDIsMmgyNGMxLjEwNCwwLDItMC44OTYsMi0yUzI5LjEwNCwxNCwyOCwxNHogTTI4LDIySDRjLTEuMTA0LDAtMiwwLjg5Ni0yLDJzMC44OTYsMiwyLDJoMjRjMS4xMDQsMCwyLTAuODk2LDItMiAgUzI5LjEwNCwyMiwyOCwyMnoiLz48L3N2Zz4=") !important;
    }
}