.ResearchComponent{
    .homeEdtech{
        background-color: #121625;
    }
    .researchContent{
        h4{
            margin: 30px 0 10px 0;
        }
        img{
            margin-top: 20px;
            max-width: 100%;
        }
    }
}