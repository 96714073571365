.checkoutPopupMain{
    color: #fff;
    
    hr {
        // background: #fff !important;
        border-color: #eaeaea !important;
    }

    button {
        > span{
            width: 26px;
            height: 26px;
            line-height: 26px;
            text-align: center;
            background: #053f7d;
            border-radius: 50%;
            font-size: 12px;
            position: absolute;
            left: 10px;
            margin: 2px auto;
            display: block;
        }
    }

    .checkoutBox{
        display: inline-block;
        min-height: 50px;
        line-height: 50px;
        border-radius: 5px;
        margin: 10px 0;
        width: 100%;
        background: #fff94c;
        border: 2px transparent solid;
        text-transform: uppercase;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        
        &:hover{
            background: #ccc;
        }

        p{
            margin: 0;
            font-weight: 600;
            font-size: 0.9rem;
            padding-left: 15px;
            padding-right: 85px;
            color: #4c7a34;
            position: relative;
            line-height: 20px;
            padding-top: 12px;
            padding-bottom: 12px;

            &::after {
                content: 'Select';
                width: 70px;
                height: 35px;
                position: absolute;
                z-index: 999;
                background: #fff;
                border: 1px #02bb7c solid;
                top: calc(50% - 17px);
                right: 7px;
                border-radius: 7px;
                font-size: 12px;
                text-transform: none;
                text-align: center;
                line-height: 35px;
            }

        }

        // &.selected{
        //     background: #fff;
        //     border: 2px #0062cc solid;
        //     box-shadow: 0px 34px 43px -23px #999;
        //     p{
        //         color: #0062cc;
        //     }
        // }

        img{
            // background: #fff;
            border-radius: 3px;
            padding: 0 5px;
            width: 46px;
            margin: 0 2px;
        }
    }

    .promoCodeButton {
        background: transparent !important;
        border: transparent;
        box-shadow: none;
        color: #fff !important;
        height: 50px;
        padding-left: 80px;
        position: relative;
        font-size: 18px;
        text-align: left;
        // margin-bottom: 120px;

        svg {
            background: #98f6d6;
            padding: 15px;
            width: 50px;
            height: 50px;
            border-radius: 10px;
            position: absolute;
            left: 20px;
            top: 0;
        }
    }

    .modal-content {
        background: #02bb7c;
        overflow: hidden;
        z-index: 1;

        // &::after{
        //     content: '';
        //     position: absolute;
        //     left: -50%;
        //     top: 50%;
        //     background: #05e397;
        //     width: 200%;
        //     height: 500px;
        //     z-index: -1;
        //     border-radius: 50%;
        // }

        .col-6 {
            color: #fff;

        }

    }

    .modal-footer {
        button {
            background: #fff;
            border: transparent;
            margin: auto;
            color: #02bb7c;
            text-transform: uppercase;
            font-weight: 500;
            padding: 10px 15px;
        }
    }

    .couponBoxDesign {
        padding: 7px;
        margin: 0;
        background: #fff;
        border-radius: 7px;
        // margin-bottom: 120px;
        
        .col-md-9,
        .col-xs-9 {
            padding-left: 0;

            input {
                background: transparent;
                border-radius: 0;
                border: none;
                color: #4c7a34;
                border-bottom: 1px #4c7a34 solid;
                outline: none !important;
                box-shadow: none;
            }
        }

        .col-md-3,
        .col-xs-3 {
            padding: 0;
            
            button {
                background: #ff3823;
                border-color: #fff;
                color: #fff;
                font-size: 12px !important;
                position: relative;
                top: 2px;
                padding: 7px 0;
                border-radius: 8px;
            }
        }

        .form-group {
            margin: 0;
        }
    }

    .custom-control {
        color: #fff;
    }

}
.payimg{
    height: 50px;
}
.paytext{
    display: inline-block;
    border: 1px solid;
    padding: 11px;
    /* z-index: -120; */
    /* margin-left: -3px; */
    border: none;
    height: 50px;
    width: calc(100% - 63px);
    text-align: center;
    cursor: pointer;
    background: #059665;
    transition: all 0.3s ease-in-out;

    &:hover{
        background: #04714c;
    }
}
.tab-content{
    text-align: left;
}
.checkoutpayment{
    h5{
    font-weight: bolder;
  
    padding-top: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #cccc;
    }
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
        color: black;
        background-color: #ffb400;
    }
    a{
        &:hover{
            color:#ffb400
        }
        color:black
    }
    h6{
        font-weight:700;
    }
}