html,body{
    overflow-x: hidden;
    
    &.modal-open{
        padding-right: 0 !important;
    }
}
.BannerWithText{
    padding: 10vh 0 !important;
    
    .slider-video{
        transform: scale(3.4);
    }

    &.white{
        &.world{
            .BannerWithText_heading{
                img{
                    display: none;
                }
            }
        }
    }
    .BannerWithText_heading{
        font-size: 2.6rem !important;
    }
    .BannerWithText_paragraph{
        font-size: 1rem !important;
    }
}
.BannerWithText_subheading{
    min-height: 150px;
    line-height: 55px;
}

.HomeComponent{
    .engineContainer{
        .col-md-8{
            text-align: center !important;

            img{
                display: inline-block;
                margin: 10px;
            }
        }

        &.imageContainer{
            > .container{
                > .row{
                    .col-md-12{
                        padding-bottom: 0 !important;
                    }
                    .col-md-4{
                        text-align: center !important;
                        padding: 30px 15px 0 15px !important;
                    }
                }
            }
        }
    }

    .titleContainer{
        &.override{
            background: #f4d506;
            top: 0;
            margin: 0;
            margin-bottom: -20px;
            padding-top: 20px;

            h2{
                font-size: 20px;
            }
        }
    }

    .homeEdtech{
        padding: 10vh 0 !important;

        &.padding-20 {
            padding: 20px 0 !important;
        }

        > div {
            background: none !important;

            > .row{
                padding: 0 !important;
            }
        }

        &.takePeak{
            background: none !important;
            padding: 7vh 0 0 0 !important;

            .onlyMobile{
                margin-top: 30px;
            }
        }

        &.leadership{
            background: none !important;
            padding: 0 0 7vh 0 !important;

            .col-md-3{
                width: 50%;
                padding: 10px;
            }
            .BannerWithText_btn{
                margin: 0;
            }
            .onlyMobile{
                margin-bottom: 50px;
            }
        }

        &.dashboard{
            padding: 7vh 0 0 0 !important;
            img{
                margin-left: -32px;
            }
        }
    }

    .stepBoxes{
        img{
            &:not(.onlyMobile){
                display: none;
            }
        }
    }
}

.MethodComponent{
    .TextWithImage{
        padding: 5vh 0 !important;

        > .container{
            > .row{
                > .col-md-6{
                    padding: 3vh 15px !important;
                    text-align: center !important;
                }
            }
        }
    }
}

.BannerWithSlider{
    .slideContainer{
        max-height: 290px;
        padding: 8vh 0 !important;
        background-size: cover !important;
        min-height: 200px !important;
    
        h1{
            font-size: 2rem;
            // padding-left: 50px;
            // padding-right: 50px; 
        }
    }
}

#react-youtube-channel-container{
    #outer-video-list-container{
        .inner-video-list-container{
            max-height: inherit !important;
            height: 440px;
        }
    }
}

.onlyMobile{
    display: inline-block;
}

.Footer{
    > .container{
        padding: 10vh 0px !important;
    }
}

.loginPopupMain{
    &.modal-dialog{
        max-width: inherit;
        margin: 0;

        &.signup{
            max-width: inherit;
        }
    }

    // .modal-body{
    //     padding: 20px 0px !important;
    // }

    .col-md-4,
    .col-md-3{
        > img {
            display: none;
        }
    }

    .LoginForm,
    .SignUpForm{
        .form-group{
            p{
                &.error{
                    bottom: -6px;
                    top: inherit;
                    background: #eaeaea;
                    right: 14px;
                }
            }
        }
    }

    &.enrollmentModal {
        min-height: 100vh;

        .modal-content {
            min-height: 100vh;
        }
    }

}



.priceBtnCourse{
    width: 100%;
    margin-bottom: 30px;
    position: fixed !important;
    left: 0;
    bottom: 0;
    margin: 0;
    border-radius: 0;
    z-index: 999;
    box-shadow: 10px 10px 10px 10px #333;

    button {
        border-radius: 0 !important;
        padding: 10px 0 !important;
        line-height: 1 !important;
    }
}

.CourseDetailComponent{
    .col-md-6{
        .priceBtnCourse{
            position: relative !important;
            z-index: 1 !important;
            width: 90%;
            margin-right: 5%;
            box-shadow: none;
        }
    }

    .card {
        margin-bottom: 20px;

        .card-body{
            .card-title{
                h2{
                    font-size: 18px;
                }
            }
            .card-subtitle{
                font-size: 14px;
            }
        }
    }

    .glanceBoxes{
        .card{
            // min-height: inherit !important;
            min-height: calc(100% - 15px) !important;
        }
    }

    .MainHeader{
        margin-bottom: 15px;
    }

}

.checkoutPopupMain{
    margin: 0 !important;
    height: 100%;

    .modal-content {
        // height: 100vh;
    }

    .modal-body{
        > div{
            > .container-fluid{
                padding: 0;
            }
        }
        .btn-group{
            display: block;
            .btn{
                // width: 100%;
                // flex: none;
                // border-radius: 0;
                font-size: 12px;

                > span {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    line-height: 17px;
                    left: -10px;
                    top: -10px;
                    z-index: 99;
                    font-size: 12px;
                    background: #fff;
                    color: #000;
                    border: 1px #000 solid;
                }
            }
        }
    }
}

.htmlModalMain{
    &.modal-dialog{
        max-width: inherit !important;
    }

    .modal-footer{
        button{
            font-size: 12px;
        }
    }
}

.CoursesComponent,
.OnlineSchoolComponent {
    .homeEdtech{
        text-align: center;
        padding: 10px 0 !important;
    }

    .filterButtons{
        padding: 0;

        p{
            display: none;
        }

        button{
            font-size: 12px;
            padding: 5px 10px;
            margin-bottom: 5px !important;
            margin-right: 5px !important;
        }
    }

    .couresGrid{
        .card{
            ul{
                min-height: inherit !important;
            }
        }
    }
}

.navbar{
    &.navbar-dark {
        .navbar-brand{
            img{
                width: 100px;
            }
        }
        .navbar-toggler{
            padding: 5px;
            font-size: 1rem;
        }
    }

    .userMenuMobile{
        margin: 0;
        margin-right: 20px;
        font-size: 12px;

        .dropdown{
            position: relative;

            .userDropDown{
                position: absolute;
                top: calc(100% + 10px);
                background: #000;
            }
        }

        .userName {
            display: inline-block;
            width: 100px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        a {
            font-size: 12px;
        }
    }
}


.CareerPageSnippetComponent{
    padding: 3vh 0 3vh 0 !important;

    .BannerWithText_heading {
        font-size: 1.7rem !important;
    }

    .BannerWithText_btn {
        font-size: 1rem !important;
    }

    .BannerWithText_paragraph {
        font-size: 1rem !important;
        width: 60%;
    }
}

.teacherDetails {
    display: block;
}


// .couponBoxDesign{
//     .form-group {
//         &:first-child {
//             margin-bottom: 0;
//         }
//     }
//     .col-md-9 {
//         padding-right: 15px;
//     }
//     .col-md-3 {
//         padding-left: 15px;
//     }
//     button,
//     input {
//         border-radius: 5px;
//     }
// }