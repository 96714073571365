.CoursesComponent{
    // background: #ecaa26d4;

    .filterButtons{
        
        button{
            margin-right: 10px;
            margin-bottom: 10px;
        }

        &.stick{
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            padding: 15px;
            background: #fff;
            box-shadow: 0px 0px 50px -25px #333;
            z-index: 999;
            text-align: center;

            p{
                display: none;
            }

            button{
                margin: 3px;
            }
        }
    }

    .homeEdtech{
        p{
            background: transparent;
            color: #000;
            border-bottom: 1px #333 solid;
            padding: 5px;
        }
    }

    .couresGrid{
        // padding-top: 0vh;
        padding-bottom: 5vh;

        &.stick{
            padding-top: 170px;
        }

        .card{
            box-shadow: 0px 28px 60px -30px #333;
            -webkit-box-shadow: 0px 28px 60px -30px #333;
            -moz-box-shadow: 0px 28px 60px -30px #333;
            -o-box-shadow: 0px 28px 60px -30px #333;
            border: 1px #fff solid !important;
            margin-bottom: 5vh;
            border: none;
            // max-height: 500px;

            .card-title{
                height: 50px;
                display: inline-table;

                h5{
                    display: table-cell;
                    vertical-align: middle;
                }
            }

            .card-img-top{
                max-height: 182px;
            }

            .badge{
                position: absolute;
                left: -12px;
                top: 6px;
                padding: 9px 15px;
                background-color: #fc0fc0;
                border: 1px #000 solid;
                border-radius: 0;
            }

            h5{
                margin-bottom: 20px;
            }
            ul{
                list-style-type: none;
                padding: 10px 0;
                border-top: 1px #eaeaea solid;
                // border-bottom: 1px #eaeaea solid;
                min-height: 168px;
                li{
                    padding: 5px 0;
                }
            }
        }
    }

}

.CourseDetailComponent{
    .card{
        // margin-bottom: 5vh;
        // min-height: 255px;

        svg{
            padding: 15px;
            background: rgba(255,255,255,0.2);
            width: 65px;
            height: 65px;
            border-radius: 50%;
        }
    }
    .coursesDemo{
        button{
            text-align: left;
            margin-bottom: 3px;

            svg{
                position: relative;
                top: 3px;
            }
        }
        .card{
            margin-bottom: 3px;

            .card-body{
                padding: 0;
            }
        }
        ul{
            padding: 0;
            margin: 0;

            li{
                width: 100%;
                display: inline-block;
                border-bottom: 1px #eaeaea solid;
                padding: 5px 10px 5px 15px;
                line-height: 34px;

                button{
                    position: relative;
                    top: 1px;
                }
            }
        }
    }

    .glanceBoxes{
        .card{
            min-height: 300px;
            width: 100%;
            display: table;

            .card-body{
                display: table-cell;
                vertical-align: middle;
            }
        }
    }

    .courseDynamicEditorContent {
        img {
            max-width: 100%;
            height: auto !important;
        }
    }

}

.htmlModalMain{
    max-width: 80vw !important;
    // margin: 0;

    .modal-content{
        height: 80vh;

        .modal-body{
            overflow-x: hidden;
            overflow-y: auto;
        }
    }

    .modal-footer{
        padding: 0;

        button{
            border-radius: 0;
            border: none;
            padding: 15px;
            background: #182842;
            color: #acc8f5;
        }
    }
    .htmlmodalerror{
        position: absolute;
        left: 0;
        text-align: center;
        width: calc(100%);
        top: 0px;
        color: #fff;
        background: #0f1a2d;
        padding: 10px;
        margin: 0;
    }

    .closeModalLogin{
        color: #fff;
        &:hover{
            color: #000;
        }
    }
}